import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError, InternalAxiosRequestConfig, AxiosHeaders, AxiosProgressEvent } from "axios";
import { apiBaseUrl } from "@/environment/environment";
import { ApiErrors, ApiException } from "@/app/shared/services/volateq-api/api-errors";
import { TableFilterRequest } from "./api-requests/common/table-requests";
import { store } from "../../stores/store";
import { TokenResult } from "./api-schemas/auth-schema";
import { waitFor } from "../helper/debounce-helper";

export class HttpClientBase {
  private readonly httpClient: AxiosInstance;
  private reloadingPermissions = false;

  protected readonly baseURL: string | undefined;

  static getRequestHeader(existingHeaders?: AxiosHeaders): AxiosHeaders {
    const headers = new AxiosHeaders(existingHeaders);
    if (store.auth.isAuthenticated) {
      headers.setAuthorization(`Bearer ${store.auth.token}`);
    }
    if (store.protection.botProtectionToken) {
      headers.set("XVolateqBotProtectionToken", store.protection.botProtectionToken);
    }

    return headers;
  }

  static createAuthHttpClient(baseURL: string): AxiosInstance {
    const httpClient = Axios.create({ baseURL });

    httpClient.interceptors.request.use((config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
      config.headers = this.getRequestHeader();

      return config;
    });

    return httpClient;
  }

  constructor() {
    this.baseURL = apiBaseUrl;
    this.httpClient = HttpClientBase.createAuthHttpClient(this.baseURL!);
    this.httpClient.interceptors.response.use(
      (response: AxiosResponse) => {
        return response.data;
      },
      async (error: AxiosError<ApiException>) => {
        if (error.response && error.response.data && error.response.data.error) {
          if (
            error.response.data.error === ApiErrors.INVALID_TOKEN ||
            error.response.data.error === ApiErrors.TOKEN_EXPIRED
          ) {
            store.auth.updateToken({ token: "", permissions: [], customer: undefined });

            location.reload();
          }
          else if (error.response.data.error === ApiErrors.PERMISSION_RELOAD_REQUIRED) {
            // The permissions of the user have changed. REST-API forces us to reload them...
            try {
              await this.reloadPermissions();

              // retry the same request again, but with a fresh token
              error.config!.headers = HttpClientBase.getRequestHeader();
              return (await Axios.request(error.config!)).data
            } catch (e) {
              return Promise.reject(e);
            }
          }

          return Promise.reject(error.response.data);
        }

        // CORS Error due to 404 of nginx (it does not set cors header...) due to missing or wrong bot protection token
        if (error.response === undefined) {
          try {
            await this.refreshProtectionToken();
            
            // retry the same request again, but with a fresh token
            error.config!.headers = HttpClientBase.getRequestHeader(error.config!.headers);
            return (await Axios.request(error.config!)).data
          } catch {
            console.error("DISCONNECT");
            console.error(error);

            return Promise.reject({
              error: ApiErrors.DISCONNECT,
              message: "No connection to server",
            });
          }
        }

        console.error("FATAL");
        console.error(error);

        return Promise.reject({
          error: ApiErrors.SOMETHING_WENT_WRONG,
          message: "Ooops! Something went horribly wrong!",
        });
      }
    );
  }

  private async refreshProtectionToken() {
    const response = await Axios.get((new URL('/bot-protection-token', this.baseURL)).toString());
    store.protection.updateBotProtectionToken(response.data)
  }

  private async reloadPermissions() {
    if (this.reloadingPermissions) {
      while (this.reloadingPermissions) {
        await waitFor(500);
      }                
    } else {
      this.reloadingPermissions = true;
      try {
        const tokenResult: TokenResult = (await Axios.post(
          `${this.baseURL}/auth/user/reload-permissions`,
          undefined,
          { headers: HttpClientBase.getRequestHeader() }
        )).data;
        
        store.auth.updateToken({
          token: tokenResult.token,
          permissions: tokenResult.permissions,
          customer: tokenResult.customer,
          ...(tokenResult.is_volateq_admin ? { isAdmin: tokenResult.is_volateq_admin } : {}),
        });
      } finally {
        this.reloadingPermissions = false;
      }
    }
  }

  protected async postForm(
    url: string,
    data: Record<string, string | File | File[] | Blob>,
    onUploadProgressEvent?: (progressEvent: AxiosProgressEvent) => void
  ): Promise<any> {
    const formData = new FormData();

    for (const key in data) {
      const value = data[key];

      if (Array.isArray(value)) {
        for (const item of value) {
          formData.append(key, item);
        }
      } else {
        formData.append(key, value);
      }
    }

    return this.httpClient.post(url, formData, { 
      headers: { "Content-Type": "multipart/form-data" }, 
      onUploadProgress: onUploadProgressEvent !== undefined ? (progressEvent: AxiosProgressEvent) => {
        onUploadProgressEvent(progressEvent);
      } : undefined
    });
  }

  protected async post(url: string, data?: any, config?: AxiosRequestConfig | undefined, params?: any): Promise<any> {
    return this.httpClient.post(this.getUrl(url, params), data, config);
  }

  protected async get(url: string, params?: any, config?: AxiosRequestConfig | undefined): Promise<any> {
    return this.httpClient.get(this.getUrl(url, params), config);
  }

  protected async delete(url: string, config?: AxiosRequestConfig | undefined): Promise<any> {
    return this.httpClient.delete(url, config);
  }

  public getUrl(url: string, params?: any) {
    return url + ((params && this.getQueryParams(params)) || "");
  }

  protected getQueryParams(params: any): string {
    return (
      "?" +
      Object.keys(params)
        .map(key => key + "=" + encodeURIComponent(params[key]))
        .join("&")
    );
  }

  protected getEncodedAnalysisResultFilterParams(filterParams?: TableFilterRequest) {
    let encodedFilterParams = "";
    if (filterParams) {
      if (filterParams.filters) {
        encodedFilterParams += `&filters=${encodeURIComponent(JSON.stringify(filterParams.filters))}`;
      }
      if (filterParams.component_filter) {
        encodedFilterParams += `&component_filter=${encodeURIComponent(JSON.stringify(filterParams.component_filter))}`;
      }
      if (filterParams.columns_selection) {
        encodedFilterParams += `&columns_selection=${encodeURIComponent(
          JSON.stringify(filterParams.columns_selection)
        )}`;
      }
    }

    return encodedFilterParams;
  }
}
