export class AnalysisResultStore {
  private refreshUnmeasuredRequired: boolean;

  constructor() {
    this.refreshUnmeasuredRequired = false;
  }

  public updateRefreshUnmeasuredRequired(refreshUnmeasuredRequired: boolean) {
    this.refreshUnmeasuredRequired = refreshUnmeasuredRequired;
  }

  public get isRefreshUnmeasuredRequired(): boolean {
    return this.refreshUnmeasuredRequired;
  }
}

export const analysisResultStore = new AnalysisResultStore();