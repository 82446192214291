import { render, staticRenderFns } from "./app-loading.vue?vue&type=template&id=25b6ca0b"
import script from "./app-loading.vue?vue&type=script&lang=ts"
export * from "./app-loading.vue?vue&type=script&lang=ts"
import style0 from "./app-loading.vue?vue&type=style&index=0&id=25b6ca0b&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jakry/code/volateq-web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25b6ca0b')) {
      api.createRecord('25b6ca0b', component.options)
    } else {
      api.reload('25b6ca0b', component.options)
    }
    module.hot.accept("./app-loading.vue?vue&type=template&id=25b6ca0b", function () {
      api.rerender('25b6ca0b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/shared/components/app-loading/app-loading.vue"
export default component.exports