import Vue from "vue";
import { AuthStore, authStore } from "./auth";
import { MobileStore, mobileStore } from "./mobile";
import { OfflineStore, offlineStore } from "./offline";
import { ProtectionStore, protectionStore } from "./protection";
import { SidebarStore, sidebarStore } from "./sidebar";
import { analysisResultStore, AnalysisResultStore } from "./analysis-result";

export interface Store {
  readonly mobile: MobileStore;
  readonly offline: OfflineStore;
  readonly protection: ProtectionStore;
  readonly sidebar: SidebarStore;
  readonly auth: AuthStore;
  readonly analysisResult: AnalysisResultStore;
}

export const store: Store = Vue.observable({
  mobile: mobileStore,
  offline: offlineStore,
  protection: protectionStore,
  sidebar: sidebarStore,
  auth: authStore,
  analysisResult: analysisResultStore,
});