<template>
  <div>
    <strong>December 2024 (v9.0.0)</strong><br>
    <hr>
    <ul>
      <li>
        <a href="#feature-1">
          <span class="text-danger pad-right-half"><app-icon icon="exclamation-triangle" /></span>
          Replace "Could not be measured" with historical results
        </a>
      </li>
      <li><a href="#feature-2">Clicking on the data table row selects the corresponding component in the map</a></li>
      <li><a href="#feature-3">Reports popup: New filter "Show reports of all analyses"</a></li>
      <li><a href="#feature-4">Quickly move flight campaigns by up to 3 days</a></li>
      <li><a v-if="isAdmin" href="#feature-5"><app-super-admin-marker /> Technology filter in analysis overview</a></li>
      <li><a href="#feature-6">Bug fixes and improvements</a></li>
    </ul>
    <hr>
    <div>
      <app-box>
        <h3 id="feature-1" class="grayed font-bold font-md mar-bottom">
          <span class="text-danger pad-right-half"><app-icon icon="exclamation-triangle" /></span>
          Replace "Could not be measured" with historical results
        </h3>
        <img :src="fillUpWithHistResultsImage" style="max-width: 40%" class="pull-left" />
        <p class="pull-left mar-left" style="max-width: calc(60% - 1em);">
          New Setting: "<b>Fill up with historical results</b>". If active, the results of components that could not be measured are filled up from previous analyses.
          This applies to both the map and the data table. By default this setting is activated for all users.
        </p>
        <div class="clear mar-bottom-2x"></div>
      </app-box>
      <app-box>
        <h3 id="feature-2" class="grayed font-bold font-md mar-bottom">Clicking on the data table row selects the corresponding component in the map</h3>
        <img :src="clickTableRowImage" style="max-width: 40%" class="pull-left" />
        <p class="pull-left mar-left" style="max-width: calc(60% - 1em);">
          In addition to highlighting the component in the map when hovering over a table row you can also click on it to jump to its location.
        </p>
        <div class="clear mar-bottom-2x"></div>
      </app-box>
      <app-box>
        <h3 id="feature-3" class="grayed font-bold font-md mar-bottom">Reports popup: New filter "Show reports of all analyses"</h3>
        <p>
          The default behaviour changed: Instead of showing reports of all analyses, only the reports of the selected analysis are visible.
          Activate the switch "<b>Show reports of all analyses</b>" to see the reports of all analyses again.
        </p>
      </app-box>
      <app-box>
        <h3 id="feature-4" class="grayed font-bold font-md mar-bottom">Quickly move flight campaigns by up to 3 days</h3>
        <p>
          Flight campaigns can now be moved by up to 3 days compared to its original start date.
          Helpful if you need to e.g. delay your flights by a day due to weather. Changes the schedule instantly and updates timings in VolaFly.
        </p>
      </app-box>
      <app-box v-if="isAdmin">
        <h3 id="feature-5" class="grayed font-bold font-md mar-bottom"><app-super-admin-marker /> Technology filter in analysis overview</h3>
        <p>
          Christmas gift for Volateq admins 💝
        </p>
      </app-box>
      <app-box>
        <h3 id="feature-6" class="grayed font-bold font-md mar-bottom">Bug fixes and improvements</h3>
        <p>
          You really read the whole release notes until this point! Cheers!<br>
          We actually fixed a few bugs and solved some special performance issues.
        </p>
      </app-box>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import BaseReleaseNotesComponent from "./base-release-notes-component"
import AppIcon from "../shared/components/app-icon/app-icon.vue";
import AppBox from "../shared/components/app-box/app-box.vue";
import AppSuperAdminMarker from "../shared/components/app-super-admin-marker/app-super-admin-marker.vue";

@Component({
  name: "app-release-notes-v14",
  components: {
    AppIcon,
    AppBox,
    AppSuperAdminMarker,
  }
})
export default class AppReleaseNotesV14 extends BaseReleaseNotesComponent {
  releaseNotesVersion = "v14"
  
  clickTableRowImage = "";
  fillUpWithHistResultsImage = "";

  async mounted() {
    this.clickTableRowImage = await this.getReleaseNoteImage(this.releaseNotesVersion, "click-table-row.png");
    this.fillUpWithHistResultsImage = await this.getReleaseNoteImage(this.releaseNotesVersion, "fill-up-with-hist-results.png");
  }
}
</script>
